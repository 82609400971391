import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { sortBy } from "lodash";

import AccountingPeriodChecklistFooter from "./AccountingPeriodChecklistFooter";
import Heading from "@components/layout/Heading";
import Label from "@components/form/Label";
import Tag from "@components/shared/Tag";
import Table from "@components/layout/Table";
import TextInput from "@components/form/TextInput";
import SelectSubscriber from "@components/form/SelectSubscriber";
import SeasonalAssignmentModal from "@components/modal/SeasonalAssignmentModal";
import ArrowRightCalendarIcon from "@components/icons/ArrowRightCalendarIcon";
import ExternalLinkIcon from "@components/icons/ExternalLinkIcon";
import SelectAdminApprovalStatus from "@components/form/SelectAdminApprovalStatus";
import { getStatusAndText } from "@utils/administrativeApproval";
import { useAppContext } from "@context/AppContext";
import { useAccountingPeriodChecksContext } from "@context/AccountingPeriodChecksContext";
import { useAllAdministrativeApprovals } from "@hooks/query/useAllAdministrativeApprovals";
import { formatDateTime } from "@utils/formatDateTime";
import { formatDate, formatDatetimeInput } from "@utils/formatDate";
import { formatVolume } from "@utils/formatVolume";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";
import { AdministrativeApprovalType } from "@services/administrativeApprovals";

import { convertMLToLiter } from "@utils/convertUnits";

type Filter = {
  sellerId: string;
  buyerId: string;
  fromLodgedAt: string;
  toLodgedAt: string;
  fromVolume: number;
  toVolume: number;
  status: number;
};

const SeasonalAssignmentsChecklist: React.FunctionComponent = () => {
  const { loggedInInfo } = useAppContext();
  const { currentAccountingPeriodCheck } = useAccountingPeriodChecksContext();
  const { t } = useTranslation();
  const [filter, setFilter] = React.useState<Partial<Filter>>({
    fromLodgedAt: "",
    toLodgedAt: "",
  });
  const [selectedRow, setSelectedRow] = React.useState<any>();

  const { data: approvals = [], isLoading } = useAllAdministrativeApprovals({
    params: {
      type: AdministrativeApprovalType.SWT,
      level1ResourceId: currentAccountingPeriodCheck?.level1ResourceId,
      accountingPeriodId: currentAccountingPeriodCheck?.accountingPeriod?.id,
    },
    options: {
      onSuccess: (data: any) => {
        if (data.length === 0) return;
        if (filter.fromLodgedAt || filter.toLodgedAt) return;
        const allTimestamps = data.map((d: any) => d.createdAt);
        const sortedTimestamps = sortBy(allTimestamps);

        const firstDate = sortedTimestamps[0];
        const lastDate = sortedTimestamps[sortedTimestamps.length - 1];

        setFilter(prevState => ({
          ...prevState,
          fromLodgedAt: firstDate,
          toLodgedAt: lastDate,
        }));
      },
    },
  });

  const tableFields = [
    {
      title: t("common.ledger_timestamp"),
      name: "createdAt",
    },
    {
      title: t("common.level1wrs"),
      name: "level1Resource",
    },
    {
      title: t("common.accounting_period"),
      name: "accountingPeriod",
    },
    {
      title: t("common.status"),
      name: "status",
    },
    {
      title: t("approval.permanent_trades.from_subscriber"),
      name: "sellerName",
    },
    {
      title: t("approval.permanent_trades.to_subscriber"),
      name: "buyerName",
    },
    {
      title: t("common.volume"),
      name: "volume",
    },
    {
      title: t("approval.permanent_trades.price"),
      name: "price",
    },
    {
      title: t("permanent_trade.lodged_at"),
      name: "lodgedAt",
    },
    {
      title: t("common.action"),
      name: "action",
    },
  ];

  const tableData = approvals.map((app: any) => ({
    ...app,
    level1Resource: app.level1Resource.name,
    createdAt: formatDateTime(new Date(app.createdAt)),
    createdAtRaw: app.createdAt,
    lodgedAt: formatDate(new Date(app.lodgedAt)),
    sellerName: app.seller.name,
    sellerId: app.seller.id,
    buyerName: app.buyer.name,
    buyerId: app.buyer.id,
    accountingPeriod: formatAccountingPeriod(app.accountingPeriod),
    volume: formatVolume(app.volume),
    volumeRaw: app.volume,
    price: `${t("common.currency")}${app.price}`,
    rawStatus: app.status,
    status: (
      <Tag status={getStatusAndText(app?.status)?.status ?? "default"}>
        {getStatusAndText(app?.status)?.text}
      </Tag>
    ),
    action: (
      <div className="flex gap-1 items-center w-full">
        <button
          type="button"
          onClick={() => setSelectedRow(app)}
          className="btn-default text-xs rounded"
        >
          {t("common.view")}
        </button>
      </div>
    ),
  }));

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  return (
    <>
      <div className="flex flex-col grow gap-4 p-4">
        <header className="flex justify-between items-center">
          <Heading light>
            {t("accounting_period_checks.seasonal_assignments")}
            <strong className="inline-block ml-2">{approvals.length}</strong>
          </Heading>
        </header>

        <form className="flex flex-wrap gap-3 justify-start">
          <div className="w-full sm:w-1/2 md:w-auto">
            <Label>{t("permanent_trade.seller")}</Label>
            <div className="max-w-max">
              <SelectSubscriber
                value={filter.sellerId}
                className="w-48"
                onChange={(e: any) => {
                  handleFilterChange("sellerId", e?.value);
                }}
                placeholder={
                  t("accounting_period_checks.choose_seller") as string
                }
              />
            </div>
          </div>
          <div className="w-full sm:w-1/2 md:w-auto">
            <Label>{t("permanent_trade.buyer")}</Label>
            <div className="max-w-max">
              <SelectSubscriber
                value={filter.buyerId}
                className="w-48"
                onChange={(e: any) => {
                  handleFilterChange("buyerId", e?.value);
                }}
                placeholder={
                  t("accounting_period_checks.choose_buyer") as string
                }
              />
            </div>
          </div>
          <div>
            <Label>{t("common.status")}</Label>
            <SelectAdminApprovalStatus
              onValueChange={value => handleFilterChange("status", value)}
              adminApprovalsAndTransfers={approvals}
            />
          </div>
          <div className="w-full md:w-auto">
            <Label htmlFor="from_volume">{t("common.volume_range")}</Label>
            <div className="flex flex-col sm:flex-row gap-1">
              <div className="max-w-max">
                <TextInput
                  type="number"
                  name="from_volume"
                  id="from_volume"
                  placeholder={t("common.date_range.from") as string}
                  className="w-32"
                  suffix={t("common.volume_unit")}
                  onChange={e => {
                    handleFilterChange("fromVolume", e.target.value);
                  }}
                  min={0}
                />
              </div>
              <div className="max-w-max">
                <TextInput
                  type="number"
                  name="to_volume"
                  placeholder={t("common.date_range.to") as string}
                  className="w-32"
                  suffix={t("common.volume_unit")}
                  onChange={e => {
                    handleFilterChange("toVolume", e.target.value);
                  }}
                  min={filter.fromVolume}
                />
              </div>
            </div>
          </div>
          <div className="w-full md:w-auto">
            <Label htmlFor="from_lodged_at">
              {t("common.ledger_timestamp")}
            </Label>
            <div className="flex flex-col sm:flex-row gap-1">
              <div className="max-w-max">
                <TextInput
                  type="datetime-local"
                  id="from_lodged_at"
                  placeholder={t("common.date_range.from") as string}
                  value={
                    filter.fromLodgedAt &&
                    formatDatetimeInput(new Date(filter.fromLodgedAt))
                  }
                  onChange={e => {
                    handleFilterChange(
                      "fromLodgedAt",
                      new Date(e.target.value),
                    );
                  }}
                />
              </div>
              <ArrowRightCalendarIcon className="w-10 h-10 hidden sm:block" />
              <div className="max-w-max">
                <TextInput
                  type="datetime-local"
                  placeholder={t("common.date_range.to") as string}
                  value={
                    filter.toLodgedAt &&
                    formatDatetimeInput(new Date(filter.toLodgedAt))
                  }
                  onChange={e => {
                    handleFilterChange("toLodgedAt", new Date(e.target.value));
                  }}
                />
              </div>
            </div>
          </div>

          <div className="flex items-end w-full ml-auto sm:w-auto">
            <Link
              to="/polestar/administrative_approvals/seasonal_transfer"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-secondary flex items-center gap-1"
            >
              <ExternalLinkIcon className="w-4 h-4" />
              {t("accounting_period_checks.add_seasonal_water_assignment")}
            </Link>
          </div>
        </form>

        <Table
          fields={tableFields}
          data={tableData
            ?.filter(
              (i: any) => !filter.buyerId || i.buyerId === filter.buyerId,
            )
            ?.filter(
              (i: any) => !filter.sellerId || i.sellerId === filter.sellerId,
            )
            ?.filter(
              (i: any) =>
                !filter.fromVolume ||
                +i.volumeRaw >= convertMLToLiter(+filter.fromVolume),
            )
            ?.filter(
              (i: any) =>
                !filter.toVolume ||
                +i.volumeRaw <= convertMLToLiter(+filter.toVolume),
            )
            ?.filter(
              (i: any) =>
                !filter.fromLodgedAt ||
                new Date(filter.fromLodgedAt).getTime() <=
                  new Date(i.createdAtRaw).getTime(),
            )
            ?.filter(
              (i: any) =>
                !filter.toLodgedAt ||
                new Date(filter.toLodgedAt).getTime() >=
                  new Date(i.createdAtRaw).getTime(),
            )
            .filter(
              (i: any) => !filter.status || filter.status === i.rawStatus,
            )}
          loading={isLoading}
          stickyHeader
        />
      </div>

      <AccountingPeriodChecklistFooter
        stepData={{
          seasonalAssignmentsCheckedAt: new Date(),
          seasonalAssignmentsCheckedByUserId: loggedInInfo?.userDetails?.id,
        }}
      />

      <SeasonalAssignmentModal
        data={selectedRow}
        onClose={() => setSelectedRow(undefined)}
      />
    </>
  );
};

export default SeasonalAssignmentsChecklist;
