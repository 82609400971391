const formatISOString = (iso: string) => {
  const [y, m, d] = iso.split("T")[0].split("-");

  return `${y}/${m}/${d}`;
};

export const formatAccountingPeriod = (accountingPeriod: {
  periodStart: string;
  periodEnd: string;
}) => {
  if (!accountingPeriod?.periodStart || !accountingPeriod?.periodEnd) {
    return "-";
  }

  return `${formatISOString(new Date(accountingPeriod.periodStart).toISOString())} → ${formatISOString(new Date(accountingPeriod.periodEnd).toISOString())}`;
};
