import React from "react";
import classNames from "classnames";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

import ApplicationActionButtons from "@components/form/ApplicationActionButtons";
import Modal from "@components/layout/Modal";
import Label from "@components/form/Label";
import TextArea from "@components/form/TextArea";
import TimestampInput from "@components/form/TimestampInput";
import Tag from "@components/shared/Tag";
import ConfirmModal from "@components/shared/ConfirmModal";
import EvidenceList from "@components/shared/EvidenceList";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";
import {
  getStatusAndText,
  getActionTimestamp,
  getActionByUser,
} from "@utils/administrativeApproval";
import { toastError, toastSuccess } from "@utils/toast";
import { formatVolume } from "@utils/formatVolume";
import { formatDate, formatDateInput } from "@utils/formatDate";
import { formatPrice } from "@utils/formatPrice";
import { useAllEventTransactions } from "@hooks/query/useAllEventTransactions";
import { useUpdateSeasonalTransfer } from "@hooks/mutation/useUpdateSeasonalTransfer";
import { AdministrativeApprovalNumericStatus } from "@services/administrativeApprovals";

type SeasonalAssignmentModalProps = {
  data?: any;
  onClose: () => void;
  actionButtons?: React.ReactNode;
};

const SeasonalAssignmentModal: React.FunctionComponent<
  SeasonalAssignmentModalProps
> = ({ data, onClose, actionButtons }) => {
  const { t } = useTranslation();
  const [confirmationModal, setConfirmationModal] = React.useState<
    "acknowledge" | "reject" | "request_info"
  >();
  const [effectiveDate, setEffectiveDate] = React.useState("");
  const [note, setNote] = React.useState("");
  const { mutateAsync: updateSeasonalTransfer, isLoading: isSubmitting } =
    useUpdateSeasonalTransfer();

  const isApproveModal = confirmationModal === "acknowledge";
  const isRejectModal = confirmationModal === "reject";
  const isRequestInfoModal = confirmationModal === "request_info";
  const { data: eventTransaction = {} } = useAllEventTransactions({
    params: {
      references: [data?.id],
      includeParentId: true,
    },
    enabled: Boolean(data?.id),
    select: (res: any) => {
      return res?.eventTransactions.filter(
        (i: any) => i.eventType?.name === "SeasonalTransferApproved",
      )?.[0];
    },
  });

  const isApproved =
    data?.status === AdministrativeApprovalNumericStatus.Approved;
  const { status: tagStatus = "default", text: statusText = "" } =
    getStatusAndText(data?.status);

  const details = [
    {
      label: t("common.level1wrs"),
      value: data?.level1Resource.name,
      show: true,
    },
    {
      label: t("common.accounting_period"),
      value: data?.accountingPeriod
        ? formatAccountingPeriod(data?.accountingPeriod)
        : "-",
      show: data?.accountingPeriod,
    },
    {
      label: t("common.volume"),
      value: formatVolume(data?.volume ?? 0),
      show: true,
    },
    {
      label: t("approval.seasonal_water_assignments.price"),
      value: formatPrice(data?.price ?? 0),
      show: true,
    },
    {
      label: t("approval.seasonal_water_assignments.reason_for_zero_trade"),
      value: data?.description ?? "-",
      show: data?.price === 0,
    },
    {
      label: t("common.effective_date"),
      value: data.effectiveDate
        ? formatDate(new Date(data.effectiveDate))
        : "-",
      show: true,
    },
    {
      label: t("common.status"),
      value: <Tag status={tagStatus}>{statusText}</Tag>,
      show: true,
    },
    {
      label: t("common.note"),
      value: data?.note ?? "-",
      show: true,
    },
    {
      label: t("common.actioned_at"),
      value: getActionTimestamp(data),
      show: true,
    },
    {
      label: t("common.actioned_by"),
      value: getActionByUser(data)?.name ?? "-",
      show: true,
    },
  ];

  const fromSubscriber = eventTransaction?.hcsMessage?.fromSubscriber ?? {};
  const toSubscriber = eventTransaction?.hcsMessage?.toSubscriber ?? {};
  const subscribers = [
    {
      id: data?.seller?.id,
      title: t("approval.seasonal_water_assignments.from_subscriber"),
      body: [
        {
          label: t("subscriber.name"),
          value: data?.seller?.name,
          show: true,
        },
        {
          label: t("subscriber.account_number"),
          value: data?.seller?.accountNumber,
          show: true,
        },
        {
          label: t("common.level0wrs"),
          value: data?.level0Resource.identifier,
          show: true,
        },
        {
          label: t("common.water_class"),
          value: data?.waterClass.name,
          show: true,
        },
        {
          label: isApproved ? t("approval.old_balance") : t("common.balance"),
          value: formatVolume(fromSubscriber.prevBalance ?? 0),
          show: isApproved,
        },
        {
          label: t("approval.new_balance"),
          value: formatVolume(fromSubscriber.newBalance ?? 0),
          show: isApproved,
          className: "text-red-500",
        },
      ],
    },
    {
      id: data?.buyer?.id,
      title: t("approval.seasonal_water_assignments.to_subscriber"),
      body: [
        {
          label: t("subscriber.name"),
          value: data?.buyer?.name,
          show: true,
        },
        {
          label: t("subscriber.account_number"),
          value: data?.buyer?.accountNumber,
          show: true,
        },
        {
          label: t("common.level0wrs"),
          value: data?.toLevel0Resource.identifier,
          show: true,
        },
        {
          label: t("common.water_class"),
          value: data?.toWaterClass.name,
          show: true,
        },
        {
          label: isApproved ? t("approval.old_balance") : t("common.balance"),
          value: formatVolume(toSubscriber.prevBalance ?? 0),
          show: isApproved,
        },
        {
          label: t("approval.new_balance"),
          value: formatVolume(toSubscriber.newBalance ?? 0),
          show: isApproved,
          className: "text-green-500",
        },
      ],
    },
  ];

  const confirmModals = [
    {
      open: isApproveModal,
      confirmText: t("common.ledger") as string,
      description: (
        <div className="space-y-4">
          <div>
            {t("approval.seasonal_water_assignments.modal.confirm.approve")}
          </div>
          <div className="font-normal text-gray-700">
            <TimestampInput
              type="date"
              id="effectiveDate"
              level1ResourceId={data?.level1ResourceId}
              value={effectiveDate && formatDateInput(new Date(effectiveDate))}
              onChange={e => setEffectiveDate(e.target.value)}
              required
            />
          </div>
          <div className="font-normal text-gray-700">
            <Label htmlFor="note" optional>
              {t("common.note")}
            </Label>
            <TextArea
              id="note"
              className="text-sm"
              placeholder={t("common.note") as string}
              onChange={e => setNote(e.target.value)}
              rows={4}
            />
          </div>
        </div>
      ),
    },
    {
      open: isRejectModal,
      confirmText: t("common.reject") as string,
      description: (
        <>
          {t("approval.seasonal_water_assignments.modal.confirm.reject")}
          <div className="mt-4 font-normal text-gray-700">
            <Label htmlFor="note">{t("common.reason_for_rejection")}</Label>
            <TextArea
              id="note"
              className="text-sm"
              placeholder={t("common.write_info_placeholder") as string}
              onChange={e => setNote(e.target.value)}
              rows={4}
              required
            />
          </div>
        </>
      ),
    },
    {
      open: isRequestInfoModal,
      confirmText: t("common.request") as string,
      description: (
        <>
          {t("common.request_more_info")}
          <div className="mt-4 font-normal text-gray-700">
            <Label htmlFor="note">{t("common.request_info_query")}</Label>
            <TextArea
              id="note"
              className="text-sm"
              placeholder={t("common.write_info_placeholder") as string}
              onChange={e => setNote(e.target.value)}
              rows={4}
              required
            />
          </div>
        </>
      ),
    },
  ];

  const handleSave = async () => {
    try {
      const status = isApproveModal
        ? AdministrativeApprovalNumericStatus.Approved
        : isRejectModal
          ? AdministrativeApprovalNumericStatus.Declined
          : isRequestInfoModal
            ? AdministrativeApprovalNumericStatus.UnderReview
            : AdministrativeApprovalNumericStatus.Pending;

      await updateSeasonalTransfer({
        id: data.id,
        status,
        note,
        effectiveDate,
      });

      const message = isApproveModal
        ? t("approval.seasonal_water_assignments.create.toast.approve")
        : isRejectModal
          ? t("approval.seasonal_water_assignments.create.toast.rejected")
          : isRequestInfoModal
            ? t("approval.seasonal_water_assignments.create.toast.req_info")
            : "";

      toastSuccess(message);
    } catch (e: any) {
      toastError(t("approval.seasonal_water_assignments.create.toast.error"));
    }
    setConfirmationModal(undefined);
    onClose();
  };

  const handleCancelConfirmation = () => {
    setNote("");
    setConfirmationModal(undefined);
  };

  return (
    <>
      <Modal open={data && !confirmationModal}>
        <div className="flex flex-col">
          <div className="border-b p-4">
            <h3 className="text-lg font-bold leading-6 text-gray-900">
              {t("approval.seasonal_water_assignments.title")}
            </h3>
          </div>
          <div className="p-4">
            <dl className="grid grid-cols-2 gap-x-4 gap-y-2 sm:grid-cols-1 mb-4">
              {details.map(i =>
                i.show ? (
                  <div
                    className="col-span-2 sm:col-span-1 flex gap-1"
                    key={i.label}
                  >
                    <dt className="whitespace-nowrap">{i.label}:</dt>
                    <dd className="text-gray-500">{i.value}</dd>
                  </div>
                ) : null,
              )}
            </dl>

            <div className="flex gap-3">
              {subscribers.map(s => (
                <a
                  key={s.id}
                  href={`/polestar/subscribers/${s.id}`}
                  className="group w-1/2 border rounded-lg p-3 flex flex-col gap-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h3 className="flex flex-row items-center gap-1.5 font-semibold">
                    {s.title}
                    <ArrowTopRightOnSquareIcon className="w-4 h-4 opacity-70 group-hover:opacity-100 transition-opacity" />
                  </h3>
                  <dl className="text-sm grid grid-cols-2 gap-x-4 gap-y-2 sm:grid-cols-1">
                    {s.body.map(i =>
                      i.show ? (
                        <div
                          className="col-span-2 sm:col-span-1 flex gap-1"
                          key={i.label}
                        >
                          <dt>{i.label}:</dt>
                          <dd
                            className={classNames(
                              i.className ?? "text-gray-500",
                            )}
                          >
                            {i.value}
                          </dd>
                        </div>
                      ) : null,
                    )}
                  </dl>
                </a>
              ))}
            </div>
          </div>

          {data?.evidences?.length ? (
            <EvidenceList data={data.evidences} />
          ) : null}

          {actionButtons ? (
            <div className="flex justify-end gap-3 p-4 border-t text-sm">
              {actionButtons}
            </div>
          ) : (
            <ApplicationActionButtons
              status={data?.status}
              onApprove={() => {
                setConfirmationModal("acknowledge");
              }}
              onCancel={() => {
                setConfirmationModal(undefined);
                onClose();
              }}
              onReject={() => {
                setConfirmationModal("reject");
              }}
              onRequireMoreInfo={() => {
                setConfirmationModal("request_info");
              }}
            />
          )}
        </div>
      </Modal>

      {confirmModals.map((i, index) => (
        <ConfirmModal
          open={i.open}
          confirmText={i.confirmText}
          onConfirm={handleSave}
          onClose={handleCancelConfirmation}
          isSubmitting={isSubmitting}
          key={`confirm-modal-${index}`}
        >
          {i.description}
        </ConfirmModal>
      ))}
    </>
  );
};

export default SeasonalAssignmentModal;
